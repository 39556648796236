/**
 * @param {string | string[]} keywords Keyword(s) to find in sentence(s).
 * @param {string | string[]} sentences Sentence(s) to lookup for keyword(s).
 * @param {boolean} caseSensitive Research is case sensitive default to false.
 * @returns {boolean} Sentence(s) is containing keyword(s).
 */

export const isSentenceContainsKeywords = (keywords, sentences, caseSensitive = false) => {
    if (!keywords || (typeof keywords !== 'string' && !Array.isArray(keywords))
        || keywords.length === 0
        || !sentences || (typeof sentences !== 'string' && !Array.isArray(sentences))) return;

    if (!Array.isArray(keywords)) keywords = [keywords];
    if (!Array.isArray(sentences)) sentences = [sentences];

    return sentences?.some(s => keywords?.some(k => k?.length > 3 && s?.toLowerCase()?.indexOf(k?.toLowerCase()) >= 0 ? true : false));
};
