import React from 'react';

import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import {
  HorizontalYellowLineShape,
  IconLectureTime,
} from '../../Atoms/Icons/Icons';
import { useIntl } from '../../../plugins/publicis-gatsby-plugin-i18n';
import useMedias from '../../hooks/useMedias';
import DocumentView from '../../Molecules/DocumentView/DocumentView';
import SocialShare from '../../Molecules/SocialShare/SocialShare';

import './ArticleSingle.scss';
import { trackCP, trackPublicationsDocument } from '../../Utils/Tracking';
import { trackActualite } from '../../Utils/Tracking';

const classNames = require('classnames');

const ArticleSingle = ({
  category,
  title,
  summary,
  reading_time,
  linkTo,
  xhtml,
  image,
  customClass,
  currentNav,
  pageName,
  page_category
}) => {
  const { processUrl } = useMedias();
  const intl = useIntl();

  const show_lecture_time = category?.id != 'publications' ? true : false;
  const show_summary = category?.id != 'publications' ? true : false;
  const show_socialshare = category?.id != 'publications' ? true : false;

  const trackEvent = (category, title) => {
    switch (category) {
      case "actualites":
        trackActualite('clic_actualite', 'media', category, title, 'media')
        break;
      case "communiques":
        trackCP('clic_cp', 'media', category, title)
        break;
      case "pdf":
        trackPublicationsDocument('clic_pdf_telecharge', 'media', 'accueil', true, 'publications', title)
        break;

    }
  }
  let title_link = linkTo;
  let isLinkPdfPage = false;
  if (title_link.indexOf('.pdf') > 0) {
    title_link = process.env.GATSBY_METADATA_SITE_URL + intl.locale + '/pdf/?file=https:' + linkTo + '&title=' + title;
    isLinkPdfPage = true;
  }

  return (
    <div className={classNames('article_single', customClass)}>
      <div className={classNames('visuel', { no_visual: !image })}>
        {
          linkTo ? (
            <a href={title_link} target="_blank" rel="noreferrer" onClick={() => trackEvent(isLinkPdfPage ? 'pdf' : page_category, title)}>
              {image && (
                <img
                  src={processUrl(image?.image_style_uri?.gatsby_profile_thumbnail)}
                  alt={title}
                  style={{ height: '100%', width: '100%', objectFit: 'cover' }}
                />
              )}
            </a>
          ) : (
            <img
              src={processUrl(image?.image_style_uri?.gatsby_profile_thumbnail)}
              alt={title}
              style={{ height: '100%', width: '100%', objectFit: 'cover' }}
            />
          )
        }

        {show_lecture_time && (
          <p className="lecture_time">
            <IconLectureTime className="time_icon" aria-label={reading_time} />
            <span>{reading_time}</span>
            <HorizontalYellowLineShape className="bottom_line" />
          </p>
        )}
      </div>
      <div className={classNames('text', { no_summary: !show_summary })}>
        {show_socialshare && (
          <SocialShare
            title={title}
            linkTo={linkTo}
            hidemobile={true}
            pageCategory={page_category}
          />
        )}
        <div
          className={classNames(
            'title_description',
            category?.id === 'publications' && currentNav === 'publications'
              ? 'show_document_icon'
              : ''
          )}
        >
          <h2>
            {isLinkPdfPage ? (
              //todo
              <a
                href={title_link}
                target={isLinkPdfPage ? '_blank' : '_self'}
                rel="noreferrer" onClick={() => trackEvent('pdf', title)}

              >
                {title}
              </a>
            ) : (
              <>{title_link ? <Link to={title_link} onClick={() => trackEvent(page_category, title)}>{title}</Link> : title}</>
            )}
          </h2>
          {category?.id === 'publications' &&
            currentNav === 'publications' &&
            linkTo && (
              <DocumentView
                data={[
                  {
                    name: title,
                    size: null,
                    link: linkTo,
                    xhtml_link: xhtml,
                    showName: false,
                    uniqueKey: 'article_',
                  },
                ]}
                currpage={pageName}

              />
            )}
          {show_summary && (
            <p dangerouslySetInnerHTML={{ __html: summary }} />
          )}
        </div>
      </div>
    </div>
  );
};

ArticleSingle.propTypes = {
  title: PropTypes.string.isRequired,
  summary: PropTypes.string,
  reading_time: PropTypes.string.isRequired,
  linkTo: PropTypes.string,
  image: PropTypes.object,
};

export default ArticleSingle;
