import React, { useState } from 'react';

import './SearchFilter.scss';

import { useIntl } from '../../../plugins/publicis-gatsby-plugin-i18n';
import { trackSearch } from '../../Utils/Tracking';

const SearchFilter = ({ onFilterChange, placeholder, splitChar, match }) => {
  const intl = useIntl();
  const [input_value, setInputValue] = useState();

  const handleChange = (e) => {
    setInputValue(e.currentTarget.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const spiltedValues = input_value != null && input_value != undefined && input_value?.split(splitChar);
    trackSearch('internal_search','media','communiques', 'accueil',input_value)
    onFilterChange(spiltedValues);
  };

  return (
    <form className="search_filter_molecule" onSubmit={handleSubmit}>
      <div className="input_field">
        <input type="text" placeholder={placeholder} onChange={handleChange} />
      </div>
      <input type="submit" value={intl.formatMessage({ id: 'search.cta.search' })} />
    </form>
  );
};

SearchFilter.defaultProps = {
  splitChar: ' ', // whitespace
  match: '^[a-zA-Z0-9]*$', // Regex => Any alphanumeric character
};

export default SearchFilter;
